import React from "react";
import "./panel-whatsapp.css";
import { GlobalContextMemorySpace } from "../../../context/globalContext";
import BuscadorPersonalizado from "components/buscador-personalizado";
import { useNavigate } from "react-router-dom";

const WhatsappComponent = () => {
  const navigate = useNavigate();
  //const [contenido, setContenido] = React.useState(null);
  const { WhatsappSectionFetchData } = React.useContext(
    GlobalContextMemorySpace
  );

  const changeSearch = async (value) => {
    navigate(`/especialidades/${value.slug}`, { replace: true });
  };

  React.useEffect(() => {
    // setContenido(
    //   WhatsappSectionFetchData?.length > 0 &&
    //     WhatsappSectionFetchData[0].contenido
    // );
  }, [WhatsappSectionFetchData]);

  return (
    <section className="section-whatsapp">
      <div className="container">
        <div className="pt-3 w-100 d-flex justify-content-center">
          <div className="container-buscador-home">
            <BuscadorPersonalizado
              url={"especialidades?_eliminado=false&titulo_contains="}
              text={"Buscar especialidad"}
              render={{ id: "id", value: "titulo" }}
              change={changeSearch}
            />
          </div>
        </div>
      </div>
    </section>
  );
};
export default WhatsappComponent;
